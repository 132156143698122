html, body, main {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-dialog {
  min-width: 200px;
  max-width: 300px;
  position: absolute;
}

.spinner, .spinner:before, .spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #fff;
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 22px;
  position: relative;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 2px;
}

.spinner:before, .spinner:after {
  content: "";
  position: absolute;
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  transform-origin: 10.4px 10.2px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  animation: loading 2s 1.5s infinite;
  top: -.2px;
  left: -.2px;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  transform-origin: 0 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  animation: loading 2s infinite;
  top: -.1px;
  left: 10.2px;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes blinkwarning {
  50% {
    border-color: #f5b505;
  }
}

@keyframes blinknotice {
  50% {
    border-color: #7ca4a7;
  }
}

.border-warning-blink {
  border: 4px solid rgba(0, 0, 0, 0);
  animation: blinkwarning 2s step-end infinite;
}

.border-notice-blink {
  border: 4px solid rgba(0, 0, 0, 0);
  animation: blinknotice 2s step-end infinite;
}

#loading-overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, .5);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#loading-spinner {
  color: #aaa;
}

#profilebutton {
  cursor: pointer;
}

@media (max-width: 576px) {
  .console-img {
    max-height: 14vh;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .console-img {
    max-height: 18vh;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .console-img {
    max-height: 25vh;
  }
}

@media (min-width: 992px) {
  .console-img {
    max-height: 30vh;
  }
}

#cookie-banner {
  width: 100%;
  opacity: .92;
  color: #eee;
  background-color: #0f0f0f;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.blogList li::marker {
  font-size: 1.1em;
}

.blogList li {
  padding-left: .5em;
}

.blogList li a {
  text-decoration: none;
}

.blogList li a:hover {
  text-decoration: underline;
}

.blogListUl {
  list-style-type: circle;
}

@media (min-width: 768px) {
  .container-max-lg {
    max-width: 960px !important;
  }
}

@media (min-width: 992px) {
  .container-max-md {
    max-width: 720px !important;
  }
}

.footer-bottom {
  background-color: #f8f8f8;
  border-top: 1px #dee2e6;
  margin-top: auto;
  display: flex;
}

.footer-bottom a {
  color: #111;
}

.header-top {
  color: #fff;
  background-color: #40356f;
}

header a {
  color: #fff !important;
}

.main-content {
  flex-direction: column;
  display: flex;
}

/*# sourceMappingURL=index.1711a7ba.css.map */
