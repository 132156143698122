html, body, main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  

/* override default modal behavior */
.modal-backdrop.show {
opacity: 0;
}
.modal-content {

}

.modal-dialog {
    position: absolute;
    min-width: 200px;
    max-width: 300px;
}

.spinner,
.spinner:before,
.spinner:after {
border-radius: 50%;
}
.spinner {
color: #ffffff;
font-size: 22px;
text-indent: -99999px;
margin: 0px auto;
position: relative;
width: 20px;
height: 20px;
box-shadow: inset 0 0 0 2px;
-webkit-transform: translateZ(0);
-ms-transform: translateZ(0);
transform: translateZ(0);
}
.spinner:before,
.spinner:after {
position: absolute;
content: "";
}
.spinner:before {
width: 10.4px;
height: 20.4px;
background: #5469d4;
border-radius: 20.4px 0 0 20.4px;
top: -0.2px;
left: -0.2px;
-webkit-transform-origin: 10.4px 10.2px;
transform-origin: 10.4px 10.2px;
-webkit-animation: loading 2s infinite ease 1.5s;
animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
width: 10.4px;
height: 10.2px;
background: #5469d4;
border-radius: 0 10.2px 10.2px 0;
top: -0.1px;
left: 10.2px;
-webkit-transform-origin: 0px 10.2px;
transform-origin: 0px 10.2px;
-webkit-animation: loading 2s infinite ease;
animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@keyframes loading {
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
}

@keyframes blinkwarning { 
    50% { border-color: #f5b505; } 
}

@keyframes blinknotice { 
   50% { border-color: #7ca4a7; } 
}


.border-warning-blink {
    animation: blinkwarning 2s step-end infinite;
    border: 4px solid transparent;
}

.border-notice-blink {
    animation: blinknotice 2s step-end infinite;
    border: 4px solid transparent;
}

#loading-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center; 
}
#loading-spinner {
color: #aaaaaa;
}

#profilebutton {
    cursor: pointer;
}

@media (max-width: 576px) {
    .console-img {
        max-height: 14vh;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .console-img {
        max-height: 18vh;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .console-img {
        max-height: 25vh;
    }
}
@media (min-width: 992px) {
    .console-img {
        max-height: 30vh;
    }
}

#cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #0f0f0f;
    opacity: .92;
    color: #eeeeee;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blogList li::marker {
    font-size: 1.1em;
}
.blogList li {
    padding-left: 0.5em;
}
.blogList li a {
    text-decoration: none;
}
.blogList li a:hover {
    text-decoration: underline;
}
.blogListUl {
    list-style-type: circle;
}

@media (min-width: 768px) {
    .container-max-lg {
    /*margin-right: auto;
    margin-left: auto;*/
    max-width: 960px !important;
    }
}

@media (min-width: 992px) {
  .container-max-md {
    /*margin-right: auto;
    margin-left: auto;*/
    max-width: 720px !important;
  }
}

.footer-bottom {
    border-top: 1px #dee2e6;
    margin-top: auto;
    display: flex;
    background-color: #f8f8f8;
    /*background-color: #40356f;
    color: #ffffff;*/
}

.footer-bottom a {
    color: #111111;
}

.header-top {
    background-color: #40356f;
    color: #ffffff;    
}

header a {
    color: #ffffff !important;
}

.main-content {
    display: flex;
    flex-direction: column;
}
